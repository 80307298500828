import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 style={{
      "marginBottom": "4rem"
    }}>Hi there, I'm Phat Pham 👋</h2>
    <p>{`A lifelong learner, tech enthusiast & open source lover from Vietnam.`}</p>
    <p>{`I am good at building MVPs. Lately I've been focusing on Cloud Native, Microservices & Serverless to build reactive systems.`}</p>
    <p>{`I also maintain `}<a parentName="p" {...{
        "href": "https://github.com/boringcodes"
      }}>{`github.com/boringcodes`}</a>{` in my freetime, a set of tools that makes developers life easier.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      